<template>
  <div>
    <step :active="active" />
    <div class="ui_realname">
      <h5>实名认证：</h5>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
        class="ui_ruleForm"
      >
        <el-form-item label="法人姓名" prop="legalName">
          <el-input
            v-model="ruleForm.legalName"
            placeholder="请输入法人姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="法人性别" prop="legalSex">
          <el-select v-model.trim="ruleForm.legalSex" filterable size="15">
            <el-option label="不愿透露" value="0">不愿透露</el-option>
            <el-option :label="$t('searchModule.male')" value="1">男</el-option>
            <el-option :label="$t('searchModule.female')" value="2">女</el-option>
            <el-option label="跨性别" value="9">跨性别</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="居住地址" prop="legalmanHomeAddr">
          <el-input
            v-model="ruleForm.legalmanHomeAddr"
            placeholder="请输入法人真实居住地址"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('searchModule.phone_number')" prop="legalMobile">
          <div class="ui_realname_iphone">
            <el-input
              placeholder="请输入法人手机号"
              style="width: 70%"
              v-model="ruleForm.legalMobile"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item :label="$t('searchModule.mailbox')" prop="legalEmail">
          <el-input
            v-model="ruleForm.legalEmail"
            placeholder="请输入法人邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="legalIdCardNo">
          <el-input
            v-model="ruleForm.legalIdCardNo"
            placeholder="请输入法人身份证号"
          ></el-input>
        </el-form-item>
        <el-form-item label="证件有效期至" prop="legalCardDeadLine">
          <el-date-picker
            v-model="ruleForm.legalCardDeadLine"
            type="date"
            placeholder="选择日期"
          >
          <!-- <el-date-picker
            :disabled="ruleForm.legalCardDeadLine === '9999-12-31'"
            v-model="ruleForm.legalCardDeadLine"
            type="date"
            placeholder="选择日期"
          > -->
          </el-date-picker>
          <span style="margin: 0 10px">或</span>
          <el-radio v-model="ruleForm.legalCardDeadLine" :label="'9999-12-31'"
            >长期</el-radio
          >
        </el-form-item>
        <el-form-item label="" prop="userId">
          <p>身份证正反面照片 (支持jpg/png, 文件 &lt; 2MB)</p>
          <div class="flex">
            <div>
              <div>
                <el-upload
                  class="avatar-uploader"
                  action="/acb/2.0/tenant/cmb/imgUpload"
                  :show-file-list="false"
                  :on-change="handleChange"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  :http-request="uploadPicture"
                  :data="{ type: 1 }"
                >
                  <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <span>身份证头像页</span>
              </div>
            </div>
            <div>
              <div style="margin-left: 20px">
                <el-upload
                  class="avatar-uploader"
                  action="/acb/2.0/tenant/cmb/imgUpload"
                  :show-file-list="false"
                  :on-change="handleChange2"
                  :on-success="handleAvatarSuccess2"
                  :before-upload="beforeAvatarUpload"
                  :http-request="uploadPicture"
                  :data="{ type: 2 }"
                >
                  <img v-if="imageUrl2" :src="imageUrl2" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <span>身份证国徽页</span>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="公司股东" prop="legalShareholdFlag">
          <el-radio-group v-model="ruleForm.legalShareholdFlag">
            <el-radio :label="1">法人</el-radio>
            <el-radio :label="0">他人</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="ruleForm.legalShareholdFlag === 0">
          <el-form-item
            label="股东姓名"
            :prop="'shareholderName'"
            :rules="{
              required: true,
              message: '股东姓名不能为空',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="ruleForm.shareholderName"
              placeholder="请输入股东姓名"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="身份证号"
            :prop="'shareholderCertno'"
            :rules="{
              required: true,
              message: '身份证号不能为空',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="ruleForm.shareholderCertno"
              placeholder="请输入身份证号"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="证件有效期至"
            :prop="'shareholderCertExpire'"
            :rules="{
              required: true,
              message: '证件有效期不能为空',
              trigger: 'blur',
            }"
          >
            <el-date-picker
              v-model="ruleForm.shareholderCertExpire"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
            <span style="margin: 0 10px">或</span>
            <el-radio
              v-model="ruleForm.shareholderCertExpire"
              :label="'9999-12-31'"
              >长期</el-radio
            >
          </el-form-item>
        </div>
        <el-form-item label="受益人" prop="legalBenefitFlag">
          <el-radio-group v-model="ruleForm.legalBenefitFlag">
            <el-radio :label="1">仅法人</el-radio>
            <el-radio :label="0">多人</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="ruleForm.legalBenefitFlag === 0">
          <div
            v-for="(benefit, index) in ruleForm.mechantBenefitList"
            :key="'benefit' + index"
          >
            <el-divider v-if="index !== 0"></el-divider>
            <el-form-item
              label="受益人姓名"
              :prop="'mechantBenefitList.' + index + '.name'"
              :rules="{
                required: true,
                message: '受益人姓名不能为空',
                trigger: 'blur',
              }"
            >
              <div class="flex" style="align-items: center">
                <el-input
                  v-model="benefit.name"
                  placeholder="请输入受益人姓名"
                ></el-input>
                <i
                  v-if="index !== 0"
                  class="el-icon-remove"
                  style="font-size: 24px; margin-left: 10px"
                  @click="deleteBeneficiary(benefit, index)"
                ></i>
              </div>
            </el-form-item>
            <el-form-item
              label="居住地址"
              :prop="'mechantBenefitList.' + index + '.manHomeAddr'"
              :rules="{
                required: true,
                message: '居住地址不能为空',
                trigger: 'blur',
              }"
            >
              <el-input
                v-model="benefit.manHomeAddr"
                placeholder="请输入居住地址"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="身份证号"
              :prop="'mechantBenefitList.' + index + '.IdCardNo'"
              :rules="{
                required: true,
                message: '身份证号不能为空',
                trigger: 'blur',
              }"
            >
              <el-input
                v-model="benefit.IdCardNo"
                placeholder="请输入身份证号"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="证件有效期至"
              :prop="'mechantBenefitList.' + index + '.cardDeadLine'"
              :rules="{
                required: true,
                message: '证件有效期不能为空',
                trigger: 'blur',
              }"
            >
              <el-date-picker

                v-model="benefit.cardDeadLine"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
              <span style="margin: 0 10px">或</span>
              <el-radio v-model="benefit.cardDeadLine" :label="'9999-12-31'"
                >长期</el-radio
              >
            </el-form-item>
          </div>
          <div style="text-align: center">
            <el-button type="text" @click="addBeneficiary()" icon="el-icon-plus">
              添加更多受益人
            </el-button>
            <el-button v-if="ruleForm.mechantBenefitList.length" type="primary" @click="saveBenefit()">{{ $t('button.preservation') }}</el-button>
          </div>
        </div>
        <!-- <div>
          <p>身份证正反面照片 (支持jpg/png/bmp, 文件 &lt; 2MB)</p>
        </div> -->
        <!-- <el-form-item label="身份证正反面照片 (支持jpg/png/bmp, 文件 < 2MB)" prop="userId">
          <el-input v-model="ruleForm.userId"></el-input>
        </el-form-item> -->
        <!-- <div>
          <p>
            <el-checkbox v-model="checked">我已阅读并同意</el-checkbox>
            <span style="color: #0f6eff; cursor: pointer"
              >《AIPARK爱泊车商户自助签约知情同意书》</span
            >
          </p>
        </div> -->
        <el-form-item style="margin-top: 20px">
          <el-button type="primary" @click="submitForm('ruleForm', 'next')"
            >下一步</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import step from "./step";
import { dateFormat } from "@/common/js/public";

export default {
  components: { step },
  data() {
    let emailRegExp = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    let iphoneRegExp =
      /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/;
    let IDRegExp =
      /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;
    let checkIphone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (!iphoneRegExp.test(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    let checkID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入身份证号码"));
      } else if (!IDRegExp.test(value)) {
        callback(new Error("请输入正确的身份证号码"));
      } else {
        callback();
      }
    };
    let checkEmail = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入邮箱"));
      } else if (!emailRegExp.test(value)) {
        callback(new Error("请输入正确的邮箱"));
      } else {
        callback();
      }
    };
    return {
      checked: false,
      imageUrl: "", // 身份证正面
      imageUrl2: "", // 身份证反面
      active: 0,
      tenantId: "",
      imgOptions: "",
      ruleForm: {
        legalName: "",
        legalSex: "",
        legalMobile: "",
        legalEmail: "",
        legalIdCardNo: "",
        legalCardBeginDate: "",
        legalCardDeadLine: "",
        legalBenefitFlag: "",
        legalShareholdFlag: "",
        shareholderName: "",
        shareholderCertno: "",
        shareholderCertExpire: "",
        legalmanHomeAddr: "",
        mechantBenefitList: [],
        checked: false,
      },
      rules: {
        legalName: [
          { required: true, message: "请输入法人名称", trigger: "blur" },
        ],
        legalSex: [
          { required: true, message: "请选择法人性别", trigger: "blur" },
        ],
        legalmanHomeAddr: [
          {
            required: true,
            message: "请输入法人真实居住地址",
            trigger: "blur",
          },
        ],
        legalCardDeadLine: [
          { required: true, message: "请选择身份证有效期", trigger: "blur" },
        ],
        legalShareholdFlag: [
          { required: true, message: "请选择公司股东", trigger: "blur" },
        ],
        legalBenefitFlag: [
          { required: true, message: "请选择受益人", trigger: "blur" },
        ],
        legalMobile: [
          { required: true, validator: checkIphone, trigger: "blur" },
        ],
        legalEmail: [
          { required: true, validator: checkEmail, trigger: "blur" },
        ],
        legalIdCardNo: [
          { required: true, validator: checkID, trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.tenantId = this.$route.query.tenantId;
    this.imgOptions = {
      tenantId: this.tenantId,
      imgType: "0001",
      step: 1,
    };
    this.getData();
  },
  methods: {
    getData() {
      this.$axios
        .get(
          "/acb/2.0/tenant/cmb/mechantCertification/byTenantId/" + this.tenantId
        )
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          } else {
            this.ruleForm = {
              legalName: res.value.legalName,
              legalSex: res.value.legalSex,
              legalMobile: res.value.legalMobile,
              legalEmail: res.value.legalEmail,
              legalIdCardNo: res.value.legalIdCardNo,
              legalCardBeginDate: res.value.legalCardBeginDate,
              legalCardDeadLine: res.value.legalCardDeadLine,
              legalBenefitFlag: res.value.legalBenefitFlag,
              legalShareholdFlag: res.value.legalShareholdFlag,
              shareholderName: res.value.shareholderName,
              shareholderCertno: res.value.shareholderCertno,
              shareholderCertExpire: res.value.shareholderCertExpire,
              legalmanHomeAddr: res.value.legalmanHomeAddr,
              mechantBenefitList: [],
            };
            if (res.value.mechantPictureList.length > 0) {
              this.imageUrl = res.value.mechantPictureList[0].pictureOssUrl;
              this.imageUrl2 = res.value.mechantPictureList[1].pictureOssUrl;
            }
            if (
              res.value.mechantBenefitList &&
              res.value.mechantBenefitList.length > 0
            ) {
              this.ruleForm.mechantBenefitList =
                res.value.mechantBenefitList.map((item) => {
                  return {
                    id: item.benefitId,
                    name: item.benefitName,
                    manHomeAddr: item.benefitHomeAddr,
                    IdCardNo: item.benefitCertNo,
                    cardDeadLine: item.benefitCertExpire,
                  };
                });
            } else {
              this.ruleForm.mechantBenefitList.push({
                id: "",
                name: "",
                manHomeAddr: "",
                IdCardNo: "",
                cardDeadLine: "",
              });
            }
          }
        });
    },
    // 上传图片
    uploadPicture(data) {
      const file = new FormData();
      const image = new FormData();
      image.append("tenantId", this.tenantId);
      if (data.data.type == 1) {
        image.append("imgType", "0001");
      } else {
        image.append("imgType", "0011");
      }
      image.append("step", 1);
      image.append("image", data.file);
      const opt = {
        method: "post",
        url: data.action,
        data: image,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
        success: (res) => {
        },
        fail: (res) => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    submitForm(formName, type) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // if (!this.checked && type == 'next') {
          //   this.$alert(
          //     "请先阅读并同意《AIPARK爱泊车商户自助签约知情同意书》",
          //     this.$t('pop_up.Tips'),
          //     {
          //       confirmButtonText: this.$t('pop_up.Determine'),
          //     }
          //   );
          //   return false;
          // }
          console.log("submit!!");
          this.ruleForm.shareholderCertExpire =
            this.ruleForm.shareholderCertExpire == "9999-12-31"
              ? this.ruleForm.shareholderCertExpire
              : dateFormat(this.ruleForm.shareholderCertExpire, "yyyy-MM-dd");
          this.$axios
            .post("/acb/2.0/tenant/cmb/saveOrUpdateCertification", {
              data: {
                tenantId: this.tenantId,
                enterpriseType: "00",
                legalName: this.ruleForm.legalName,
                legalSex: this.ruleForm.legalSex,
                legalMobile: this.ruleForm.legalMobile,
                legalEmail: this.ruleForm.legalEmail,
                legalIdCardNo: this.ruleForm.legalIdCardNo,
                legalCardDeadLine:
                  this.ruleForm.legalCardDeadLine == "9999-12-31"
                    ? this.ruleForm.legalCardDeadLine
                    : dateFormat(this.ruleForm.legalCardDeadLine, "yyyy-MM-dd"),
                legalBenefitFlag: this.ruleForm.legalBenefitFlag,
                legalShareholdFlag: this.ruleForm.legalShareholdFlag,
                shareholderName:
                  this.ruleForm.legalShareholdFlag != 1
                    ? this.ruleForm.shareholderName
                    : "",
                shareholderCertno:
                  this.ruleForm.legalShareholdFlag != 1
                    ? this.ruleForm.shareholderCertno
                    : "",
                shareholderCertExpire:
                  this.ruleForm.legalShareholdFlag != 1
                    ? this.ruleForm.shareholderCertExpire
                    : "",
                legalmanHomeAddr: this.ruleForm.legalmanHomeAddr,
              },
            })
            .then((res) => {
              if (res.state != 0) {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              } else {
                if (type == "next") {
                  // 下一步
                  this.$router.push({
                    path: "/businessMessage",
                    query: {
                      tenantId: this.tenantId,
                    },
                  });
                } else {
                  // 保存多个受益人
                  this.getData();
                }
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    handleChange(file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    handleAvatarSuccess2(res, file) {
      this.imageUrl2 = URL.createObjectURL(file.raw);
    },
    handleChange2(file) {
      this.imageUrl2 = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const suffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      const isJpg = suffix === ('jpg' || 'jpeg' || 'JPG' || 'JPEG')
      const isPng = suffix === ('png' || 'PNG')
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJpg  && !isPng) {
        this.$message.warning('图片格式错误，只支持jpg和png格式!')
        return false
      }
      if (!isLt2M) {
        this.$message.warning('上传图片大小不能超过 2M!')
        return false
      }
      return true
    },
    addBeneficiary() {
      this.ruleForm.mechantBenefitList.push({
        id: "",
        name: "",
        manHomeAddr: "",
        IdCardNo: "",
        cardDeadLine: "",
      });
    },
    deleteBeneficiary(benefit, index) {
      this.ruleForm.mechantBenefitList.splice(index, 1);
      if (benefit.id == "") {
        return false;
      }
      this.$axios
        .post("/acb/2.0/tenant/cmb/deleteBenefit", {
          data: {
            benefitId: benefit.id,
          },
        })
        .then((res) => {
          if (res.state != 0) {
          } else {
            this.submitForm("ruleForm", "save");
          }
        });
    },
    saveBenefit() {
      // 最后一条数据用于新增
      const data =
        this.ruleForm.mechantBenefitList[
          this.ruleForm.mechantBenefitList.length - 1
        ];
      if (data.id != "") {
        return false;
      }
      this.$axios
        .post("/acb/2.0/tenant/cmb/saveBenefit", {
          data: {
            tenantId: this.tenantId,
            benefitName: data.name,
            benefitCertNo: data.IdCardNo,
            benefitCertExpire: data.cardDeadLine,
            benefitHomeAddr: data.manHomeAddr,
          },
        })
        .then((res) => {
          if (res.state != 0) {
          } else {
            this.submitForm("ruleForm", "save");
          }
        });
    },
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.flex {
  display: flex;
}

.ui_realname {
  padding: 20px;
  margin-top: 20px;
  background-color: #fff;

  >h5 {
    margin-bottom: 20px;
    font-weight: 400;
  }
}

.ui_ruleForm {
  width: 50%;
}

.avatar-uploader /deep/ .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader /deep/ .el-upload:hover {
  border-color: #0f6eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.ui_realname_iphone {
  display: flex;
  justify-content: space-between;
}
</style>
